document.addEventListener("turbolinks:load", () => {
  // Get the Select elements in the current document
  var elem = document.querySelectorAll<HTMLSelectElement>("[id=candidate_job_position_id]");
  // The dom parser is used for creating a translated message for the select
  var parser = new DOMParser();

  /**
   * Open/Close the add-new-position modal
   * @param {boolean} shouldOpen
   */
  function setModalState(shouldOpen: boolean) {
    // Get the add-new-position modal
    var modalElem = document.getElementById("add-position-modal");

    if (shouldOpen) {
      return modalElem.setAttribute("class", "modal show");
    } else {
      return modalElem.setAttribute("class", "modal");
    }
  }

  for (var index = 0; index < elem.length; index++) {
    var element = elem[index];
    // Get and parse the translation message form the data attr.
    var translation = parser.parseFromString(element.dataset.addtranslation, "text/html").body;

    // Create a new option, set the text to the translated message.
    var opt = document.createElement("option");
    opt.appendChild(translation);
    opt.setAttribute("value", "new-position");
    element.options.add(opt);

    // If the new option is chosen, open the modal..
    element.addEventListener("change", function(event) {
      const target = event.target as HTMLSelectElement;
      if (target.value === "new-position") {
        setModalState(true);
      }
    });
  }

  function handleNewPositionCompletion(event: any) {
    // Get the part of the response we need.
    var parsedData = JSON.parse(event.detail[0].response);

    // Close the modal
    setModalState(false);

    for (let index = 0; index < elem.length; index++) {
      const element = elem[index];
      // Create a new option, set the value and text to the response data
      const option = document.createElement("option");
      option.text = parsedData.title;
      option.setAttribute("value", parsedData.id);
      element.add(option);

      // Select the new option
      element.value = parsedData.id;
    }
  }

  document.body.addEventListener("ajax:complete", handleNewPositionCompletion, false);
});
