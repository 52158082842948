import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import Turbolinks from "turbolinks";
import toastr from "toastr";

import "../stylesheets/application.scss";
import "./mediaquery";
import "./dropdown";
import "./slideover";
import "./sidebar";
import "./modal";

Rails.start();
ActiveStorage.start();

window.toastr = toastr;

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-bottom-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: 1000,
  hideDuration: 500,
  timeOut: 5000,
  extendedTimeOut: 2500,
  showEasing: "swing",
  hideEasing: "swing",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

Turbolinks.start();

// FST: This is to handle anchored links (link.com/index#fragment)
document.addEventListener("turbolinks:before-visit", function(e: any) {
  var parser = document.createElement("a");

  parser.href = e.data.url;

  if (
    parser.host == window.location.host &&
    parser.pathname == window.location.pathname
  ) {
    e.preventDefault();
    window.location.href = e.data.url;
  }
});

document.addEventListener("turbolinks:load", () => {
  document
    .querySelectorAll("[data-js-client-async]")
    .forEach((elm: HTMLElement) => {
      var url = elm.dataset.url;

      Rails.ajax({
        type: "GET",
        url: url,
        success: function(response) {
          elm.innerText = response.result;
        },
        error: function(response) {
          toastr.error(response.error);
        },
      });
    });
});
