document.addEventListener("turbolinks:load", () => {
  // Overlay shows and hides when a slideover opens and closes.
  const overlay = document.querySelector("[data-js-overlay]");

  // Fades the overlay in
  function showOverlay() {
    overlay.classList.remove("hidden");

    window.setTimeout(() => overlay.classList.add("opacity-50"), 0);
  }

  // Fades the overlay out
  function hideOverlay() {
    overlay.addEventListener(
      "transitionend",
      () => overlay.classList.add("hidden"),
      { once: true }
    );

    overlay.classList.remove("opacity-50");
  }

  // When we click the overlay, we want to close the open slideover and
  // hide the overlay.
  overlay?.addEventListener("click", () => {
    document
      .querySelector(".slideover-visible")
      .classList.remove("slideover-visible");

    hideOverlay();
  });

  document.querySelectorAll("[data-js-slideover]").forEach(slideover => {
    // When the slideover open/close transition is finished we fire an appropiate
    // for use in other places.
    slideover.addEventListener("transitionend", () => {
      if (slideover.classList.contains("slideover-visible")) {
        slideover.dispatchEvent(new Event("slideover:open"));
      } else {
        slideover.dispatchEvent(new Event("slideover:close"));
      }
    });

    // When a slideover is closing, we hide the overlay.
    slideover.addEventListener("slideover:closing", hideOverlay);

    // When a sliderover is openning, we show the overlay.
    slideover.addEventListener("slideover:opening", () => showOverlay());
  });

  document.querySelectorAll("[data-js-slideover-toggle]").forEach(element => {
    element.addEventListener("click", function() {
      // The the matching slideover.
      const slideover = document.querySelector<HTMLElement>(
        `[data-js-slideover="${this.dataset.jsSlideoverToggle}"]`
      );

      // Fire opening/closing event dependening on the slideover-visible
      // class being present.
      if (slideover.classList.contains("slideover-visible")) {
        slideover.dispatchEvent(new Event("slideover:closing"));
      } else {
        slideover.dispatchEvent(new Event("slideover:opening"));
      }

      slideover.classList.toggle("slideover-visible");
    });
  });
});
