import Rails from "@rails/ujs";
import debounce from "lodash.debounce";

import "./application";
import "./dashboard";
import "../stylesheets/clients.scss";

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("[data-js-submit-on-change]").forEach((form) => {
    form.addEventListener(
      "input",
      debounce(function() {
        Rails.fire(this, "submit");
      }, 300)
    );
  });

  // When the new candidate slideover is shown, autofocus the first input
  const newCandidateSlideover = document.querySelector(
    '[data-js-slideover="new-candidate"]'
  );

  newCandidateSlideover?.addEventListener("slideover:open", function() {
    this.querySelector('input[type="text"]').focus();
  });
  newCandidateSlideover?.addEventListener("slideover:close", function() {
    this.querySelector("form").reset();
  });

  // Hide sort slideover after link click
  const sortSlideover = document.querySelector('[data-js-slideover="sort"]');

  sortSlideover?.addEventListener("click", (event) => {
    const target = event.target as HTMLElement;
    if (target.tagName === "A" && "remote" in target.dataset) {
      sortSlideover.dispatchEvent(new Event("slideover:closing"));
      sortSlideover.classList.remove("slideover-visible");
    }
  });

  // At most one of the state checkboxes in the search form should be checked
  const searchFormCheckboxes = document.querySelectorAll<HTMLInputElement>(
    '[data-js-search-form] input[type="checkbox"]'
  );

  searchFormCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", function() {
      if (this.checked) {
        searchFormCheckboxes.forEach(
          (otherCheckbox) => (otherCheckbox.checked = false)
        );
        this.checked = true;
      }
    });
  });
});
