(() => {
  function addListeners(event) {
    document.querySelectorAll<HTMLElement>(".modal").forEach((element) => {
      window.onclick = function(event) {
        if (event.target == element) {
          document.dispatchEvent(new Event("modal:close"));
        }
      };
      element.querySelector(".close").addEventListener("click", () => {
        document.dispatchEvent(new Event("modal:close"));
      });

      document.addEventListener("modal:close", () => {
        element.classList.remove("show");
      });
    });

    document
      .querySelectorAll("[data-toggle='modal']")
      .forEach((element: HTMLElement) => {
        element.addEventListener("click", () => {
          document.querySelector(element.dataset.target).classList.add("show");
        });
      });
  }

  document.addEventListener("turbolinks:load", addListeners, false);
  document.addEventListener("ajax:success", addListeners, false);
})();
