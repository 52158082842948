import { eventNames } from "cluster";

(() => {
  function addListeners(event) {
    document.addEventListener(
      "click",
      (event) => {
        const target = event.target as HTMLElement;
        if ("jsDropdownToggle" in target.dataset) {
          document.querySelectorAll(".dropdown-open").forEach((el: HTMLElement) => {
            if (target == el) return;

            el.classList.remove("dropdown-open");
          });
          target.parentElement.classList.toggle("dropdown-open");
          event.stopPropagation();
        } else if ("jsDropdownToggle" in target.parentElement.dataset) {
          document.querySelectorAll(".dropdown-open").forEach((el: HTMLElement) => {
            if (target.parentElement.parentElement == el) return;

            el.classList.remove("dropdown-open");
          });
          target.parentElement.parentElement.classList.toggle("dropdown-open");
          event.stopPropagation();
        } else {
          document.querySelectorAll(".dropdown-open").forEach((el: HTMLElement) => {
            if (target == el) return;

            el.classList.remove("dropdown-open");
          });
        }
      },
      true
    );
  }

  document.addEventListener("turbolinks:load", addListeners, false);
  document.addEventListener("turbolinks:render", addListeners, false);
})();
