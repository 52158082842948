// `document` fires a `breakpointchange` event when the CSS breakpoint defined
// by tailwind changes.
(() => {
  function getMediaqueryScreen() {
    const content = window
      .getComputedStyle(document.getElementById("mediaquery"), ":after")
      .getPropertyValue("content");

    return content.replace(/"/g, "");
  }

  function fireEvent() {
    document.dispatchEvent(
      new CustomEvent("breakpointchange", {
        detail: { screen: getMediaqueryScreen() },
      })
    );
  }

  document.addEventListener("turbolinks:load", () => {
    const mediaquery = document.getElementById("mediaquery");

    if (mediaquery) {
      fireEvent();
      mediaquery.addEventListener("transitionend", fireEvent);
    }
  });
})();
