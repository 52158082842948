let setup = () => {
	document.querySelectorAll(".sidebar .closeBtn").forEach((e) => {
		e.addEventListener("click", (ev: MouseEvent) => {
			let target = ev.target as HTMLElement;
			target.parentElement.style.display = "none"
		})})

	let button = document.getElementById("actionbtn") as HTMLElement;
	button?.addEventListener("click", (ev: MouseEvent) => {
		let btn = ev.target as HTMLElement;
		let target = document.querySelector<HTMLElement>(btn.dataset.sidebar);
		target.style.display = "block";
		
	});

	let languagebutton = document.getElementById("languagebtn") as HTMLElement;
	languagebutton?.addEventListener("click", (ev: MouseEvent) => {
		let languagebtn = ev.target as HTMLElement;
		let languageTarget = document.querySelector<HTMLElement>(languagebtn.dataset.languageOptions);
		languageTarget.style.display = "block";
		
	});



};

document.addEventListener("turbolinks:load", setup);
document.addEventListener("ready", setup);
